export const MapperFields = {
    NAME: 'name',
    SKU: 'sku',
    SKU_FROM_API: 'sku_from_api',
    PARENT_SKU: 'parentSku',
    EXTERNAL_ID: 'externalId',
    BARCODE: 'barcode',
    DESCRIPTION: 'description',
    DESCRIPTION_SHORT: 'descriptionShort',
    ADDITIONAL_DESCRIPTION: 'additionalDescription',
    BRAND: 'brand',
    CATEGORIES: 'categories',
    GENDER: 'gender',
    WEIGHT: 'weight',
    CURRENCY: 'currency',
    STATUS: 'status',
    STOCK: 'stock',
    PRICE: 'price',
    URL: 'url',
    UPC: 'upc',
    PICTURES: 'pictures',
    OUTLET_PRICE: 'outletPrice',
    DISCOUNTED_PRICE: 'discountedPrice',
    PROMOTIONS: 'promotions',
    ECO_TAX: 'ecoTax',
    TAX_RATE: 'taxRate',
    SOLD_PHYSICALLY: 'soldPhysically',
    LOCALE: 'locale',
    MANUFACTURER_NAME: 'manufacturerName',
    MANUFACTURER_DESCRIPTION: 'manufacturerDescription',
    MANUFACTURER_ADDRESS: 'manufacturerAddresses',
    TAGS: 'tags',
    EAN: 'ean',
    CORNER: 'corner',
    CATALOG_ONLY: 'catalogOnly',
    PRIVATE_PRICE: 'privatePrice',
    PRIVATE_PRODUCT: 'privateProduct',
    HIDDEN_PRICE: 'hiddenPrice',
    STOCK_UPDATED_AT_DATE: 'stockUpdatedAtDate',
    WAREHOUSE: 'warehouse'
}

export function getAllMapperFields(options) {
    const allFields = [];

    for (const field in MapperFields) {
        allFields.push(MapperFields[field]);
    }

    options.forEach((option) => allFields.push(option.id));

    return allFields;
}

export function getMerchandisingMapperFields() {
    return [
        MapperFields.SKU,
        MapperFields.PARENT_SKU,
        MapperFields.DESCRIPTION,
        MapperFields.ADDITIONAL_DESCRIPTION,
        MapperFields.PICTURES,
        MapperFields.NAME,
        MapperFields.BRAND
    ]
}

export function getCharacteristicsMapperFields(options) {
    const characteristicsMapperFields = [
        MapperFields.CATEGORIES,
        MapperFields.CORNER,
        MapperFields.GENDER,
    ];

    options.forEach((option) => characteristicsMapperFields.push(option.id));

    characteristicsMapperFields.push(MapperFields.WEIGHT);

    return characteristicsMapperFields;
}

export function getStocksAndStatusMapperFields() {
    return [
        MapperFields.STOCK,
        MapperFields.STATUS,
        MapperFields.STOCK_UPDATED_AT_DATE,
        MapperFields.WAREHOUSE
    ]
}

export function getPricesAndPromotionsMapperFields() {
    return [
        MapperFields.PRICE,
        MapperFields.OUTLET_PRICE,
        MapperFields.DISCOUNTED_PRICE,
        MapperFields.PROMOTIONS,
        MapperFields.ECO_TAX,
    ]
}

export function getOptionsMapperFields() {
    return [
        MapperFields.SOLD_PHYSICALLY,
        MapperFields.URL,
        MapperFields.EXTERNAL_ID,
        MapperFields.BARCODE,
        MapperFields.LOCALE,
        MapperFields.CURRENCY,
        MapperFields.TAX_RATE,
        MapperFields.CATALOG_ONLY,
        MapperFields.PRIVATE_PRICE,
        MapperFields.PRIVATE_PRODUCT,
        MapperFields.HIDDEN_PRICE
    ]
}

export function getStockFeedMapperFields() {
    return [
        MapperFields.SKU,
        MapperFields.STOCK,
        MapperFields.STATUS,
        MapperFields.STOCK_UPDATED_AT_DATE,
        MapperFields.WAREHOUSE
    ]
}
export function getAdditionalMapperFieldsNamesFromConfiguration(configurationMapperFields, options) {
    const configurationMapperFieldNames = [];

    Object.keys(configurationMapperFields).forEach((fieldName) => {
        if (!getAllMapperFields(options).includes(fieldName)) {
            configurationMapperFieldNames.push(fieldName)
        }
    });

    return configurationMapperFieldNames
}
